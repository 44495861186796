// istanbul ignore file -- This is still a demo component
import { Table } from "@simplicate/ui";
import { useDataset } from "../../../data";
import { useDashboardContext } from "../../Dashboard";

type Poc1TableProps = {
  cube: string;
  columns: { header: string; dimension: string }[];
  orderBy?: string;
  orderDirection?: "asc" | "desc";
};

export const Poc1Table = ({ cube, columns, orderBy, orderDirection = "asc" }: Poc1TableProps) => {
  const {
    state: { filters },
  } = useDashboardContext();

  const { resultSet, isLoading, error } = useDataset({
    cube,
    dimensions: columns.map(({ dimension }) => dimension),
    filters,
    order: orderBy ? { [orderBy]: orderDirection } : undefined,
  });

  if (error) {
    return <div>{error.message}</div>;
  }
  if (isLoading || !resultSet) {
    return null;
  }

  const headers = columns.map(({ header, dimension }) => ({ header: header, field: `${cube}.${dimension}` }));

  return (
    <Table
      columns={headers}
      value={resultSet.tablePivot().map((r) => headers.reduce((out, { field }) => ({ ...out, [field]: r[field] }), {}))}
    />
  );
};
